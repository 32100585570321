import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ADD_DAYS_TO_USERS,
  REMOVE_DAYS_FROM_USERS,
} from "constants/ActionTypes";
import {
  AddDaysToUsersSuccess,
  RemoveDaysFromUsersSuccess,
  ShowNotificationAlert,
} from "actions";
import request from 'utils/connector';

const addDaysToUsers = async (obj) =>
  await request({
    path: '/add-days-to-users',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const removeDaysFromUsers = async (obj) =>
  await request({
    path: '/remove-days-from-users',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* AddDaysToUsers({ payload }) {
  const obj = payload;

  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Adding days to users" }));
    const failedEmails = yield call(addDaysToUsers, obj);
    yield put(AddDaysToUsersSuccess(failedEmails));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* RemoveDaysFromUsers({ payload }) {
  const obj = payload;

  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Removing days from users" }));
    const failedEmails = yield call(removeDaysFromUsers, obj);
    yield put(RemoveDaysFromUsersSuccess(failedEmails));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* AddDaysToUsersSaga() {
  yield takeEvery(ADD_DAYS_TO_USERS, AddDaysToUsers);
}

export function* RemoveDaysFromUsersSaga() {
  yield takeEvery(REMOVE_DAYS_FROM_USERS, RemoveDaysFromUsers);
}

export default function* rootSaga() {
  yield all([
    fork(AddDaysToUsersSaga),
    fork(RemoveDaysFromUsersSaga),
  ]);
}