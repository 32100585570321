import { all } from 'redux-saga/effects';
import AuthSagas from './Auth';
import BonusSagas from './Bonus';
import ExpiryIntervalSagas from './ExpiryInterval';
import PaymentSagas from './Payment';
import ProfileSagas from './Profile';
import SubtoolSagas from './Subtool';
import ToolSagas from './Tool';
import UserSagas from './User';
import UserToolSagas from './UserTool';

export default function* rootSaga() {
  yield all([
    AuthSagas(),
    BonusSagas(),
    UserSagas(),
    PaymentSagas(),
    SubtoolSagas(),
    ToolSagas(),
    ProfileSagas(),
    UserToolSagas(),
    ExpiryIntervalSagas(),
  ]);
}
