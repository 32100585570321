import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  FETCH_BRIEFS,
  UPDATE_BRIEFS,
  SHOW_AUTH_LOADER,
  HIDE_AUTH_LOADER
} from 'constants/ActionTypes';

export const ResetPassword = (email) => {
  return {
    type: RESET_PASSWORD,
    payload: email
  };
};

export const ResetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS
  };
};

export const SignIn = (user) => {
  return {
    type: SIGNIN,
    payload: user
  };
};

export const SignInSuccess = (token) => {
  return {
    type: SIGNIN_SUCCESS,
    payload: token
  };
};

export const SignOut = () => {
  return {
    type: SIGNOUT
  };
};

export const SignOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS
  };
};

export const FetchBriefs = () => {
  return {
    type: FETCH_BRIEFS
  };
};

export const UpdateBriefs = (brief_info) => {
  return {
    type: UPDATE_BRIEFS,
    payload: brief_info
  };
};

export const ShowAuthLoader = () => {
  return {
    type: SHOW_AUTH_LOADER,
  };
};

export const HideAuthLoader = () => {
  return {
    type: HIDE_AUTH_LOADER,
  };
};
