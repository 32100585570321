/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
    AppBar,
    Button,
    IconButton,
    Toolbar,
    Hidden,
    colors,
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'

import useRouter from 'utils/useRouter'
import { SignOut } from 'actions'

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1,
    },
    search: {
        backgroundColor: 'rgba(255,255,255, 0.1)',
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center',
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: 'inherit',
    },
    searchInput: {
        flexGrow: 1,
        color: 'inherit',
        '& input::placeholder': {
            opacity: 1,
            color: 'inherit',
        },
    },
    searchPopper: {
        zIndex: theme.zIndex.appBar + 100,
    },
    searchPopperContent: {
        marginTop: theme.spacing(1),
    },
    trialButton: {
        marginLeft: theme.spacing(2),
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900],
        },
    },
    trialIcon: {
        marginRight: theme.spacing(1),
    },
    navButtons: {
        marginLeft: theme.spacing(1),
        padding: theme.spacing(1),
        minWidth: 'initial',
        display: 'flex',
        justifyContent: 'center'
    },
    logoutButton: {
        marginLeft: theme.spacing(1),
    },
    logoutIcon: {
        marginRight: theme.spacing(1),
    },
}))

const TopBar = (props) => {
    const { onOpenNavBarMobile, className, ...rest } = props

    const classes = useStyles()
    const { history } = useRouter()
    const searchRef = useRef(null)
    const dispatch = useDispatch()
    const toolsRef = useRef(null)
    const quickLinksRef = useRef(null)
    const [toolsDropdownOpen, setOpenToolsDropdown] = useState(false)
    const [quickLinksOpen, setOpenQuickLinks] = useState(false)
    const [tools, setTools] = useState([])

    const AuthState = useSelector(({ Auth }) => Auth);

    const ErrorScreenState = useSelector(({ ErrorScreen }) => ErrorScreen);

    useEffect(() => {
        if (AuthState.brief_info && AuthState.brief_info.tools) {
            setTools(AuthState.brief_info.tools)
        }
    }, [AuthState.brief_info])

    const handleLogout = () => {
        dispatch(SignOut())
    }

    const handleToolsDropdownOpen = () => {
        setOpenToolsDropdown(true)
    }

    const handleToolsDropdownClose = () => {
        setOpenToolsDropdown(false)
    }

    const handleQuickLinksOpen = () => {
        setOpenQuickLinks(true)
    }

    const handleQuickLinksClose = () => {
        setOpenQuickLinks(false)
    }

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="primary">
            <Toolbar>
                <RouterLink to="/">
                    <img alt="Logo" src="/images/logos/logo.png" height={40} />
                </RouterLink>
                <div className={classes.flexGrow} />
                <Hidden mdDown>
                    <Button
                        className={classes.logoutButton}
                        color="inherit"
                        onClick={handleLogout}>
                        <InputIcon className={classes.logoutIcon} />
                        Sign out
                    </Button>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onOpenNavBarMobile}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func,
}

export default TopBar
