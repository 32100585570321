import {
  CREATE_SUBTOOL,
  CREATE_SUBTOOL_SUCCESS,
  FETCH_SUBTOOL,
  FETCH_SUBTOOL_SUCCESS,
  PAUSE_SUBTOOL,
  PAUSE_SUBTOOL_SUCCESS,
  UPDATE_SUBTOOL,
  UPDATE_SUBTOOL_SUCCESS,
  DELETE_SUBTOOL,
  DELETE_SUBTOOL_SUCCESS,
} from 'constants/ActionTypes';

export const CreateSubtool = (obj) => {
  return {
    type: CREATE_SUBTOOL,
    payload: obj
  };
};

export const CreateSubtoolSuccess = () => {
  return {
    type: CREATE_SUBTOOL_SUCCESS
  };
};

export const FetchSubtool = (name) => {
  return {
    type: FETCH_SUBTOOL,
    payload: name
  };
};

export const FetchSubtoolSuccess = (subtool) => {
  return {
    type: FETCH_SUBTOOL_SUCCESS,
    payload: subtool
  };
};

export const PauseSubtool = (id) => {
  return {
    type: PAUSE_SUBTOOL,
    payload: id
  };
};

export const PauseSubtoolSuccess = () => {
  return {
    type: PAUSE_SUBTOOL_SUCCESS
  };
};

export const UpdateSubtool = (obj) => {
  return {
    type: UPDATE_SUBTOOL,
    payload: obj
  };
};

export const UpdateSubtoolSuccess = () => {
  return {
    type: UPDATE_SUBTOOL_SUCCESS
  };
};

export const DeleteSubtool = (obj) => {
  return {
    type: DELETE_SUBTOOL,
    payload: obj
  };
};

export const DeleteSubtoolSuccess = () => {
  return {
    type: DELETE_SUBTOOL_SUCCESS
  };
};