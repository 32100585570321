import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  PAUSE_SUBTOOL,
  UPDATE_SUBTOOL,
  DELETE_SUBTOOL,
  CREATE_SUBTOOL,
  FETCH_SUBTOOL,
} from "constants/ActionTypes";
import {
  PauseSubtoolSuccess,
  UpdateSubtoolSuccess,
  DeleteSubtoolSuccess,
  CreateSubtoolSuccess,
  FetchSubtoolSuccess,
  ShowNotificationAlert,
  FetchingPageDataSuccess,
  FetchingPageData,
  ShowErrorScreen,
} from "actions";
import request from 'utils/connector';

const createSubtool = async (obj) =>
  await request({
    path: '/subtools',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const fetchSubtool = async (name) =>
  await request({
    path: '/subtools?name=' + name,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const pauseSubtool = async (id) =>
  await request({
    path: '/subtools/pause',
    method: 'POST',
    data: { id }
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const updateSubtool = async (obj) =>
  await request({
    path: '/subtools',
    method: 'PUT',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const deleteSubtool = async (obj) =>
  await request({
    path: '/subtools/delete',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* CreateSubtool({ payload }) {
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Creating Subtool" }));
    const obj = payload;
    yield call(createSubtool, obj);
    yield put(CreateSubtoolSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* FetchSubtool({ payload }) {
  try {
    yield put(FetchingPageData());

    const name = payload;
    const subtool = yield call(fetchSubtool, name);
    yield put(FetchSubtoolSuccess(subtool));

    yield put(FetchingPageDataSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

function* PauseSubtool({ payload }) {
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Pausing Subtool" }));
    const id = payload;
    yield call(pauseSubtool, id);
    yield put(PauseSubtoolSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* UpdateSubtool({ payload }) {
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Updating Subtool" }));
    const obj = payload;
    yield call(updateSubtool, obj);
    yield put(UpdateSubtoolSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* DeleteSubtool({ payload }) {
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Deleting Subtool" }));
    const obj = payload;
    yield call(deleteSubtool, obj);
    yield put(DeleteSubtoolSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* CreateSubtoolSaga() {
  yield takeEvery(CREATE_SUBTOOL, CreateSubtool);
}

export function* FetchSubtoolSaga() {
  yield takeEvery(FETCH_SUBTOOL, FetchSubtool);
}

export function* PauseSubtoolSaga() {
  yield takeEvery(PAUSE_SUBTOOL, PauseSubtool);
}

export function* UpdateSubtoolSaga() {
  yield takeEvery(UPDATE_SUBTOOL, UpdateSubtool);
}

export function* DeleteSubtoolSaga() {
  yield takeEvery(DELETE_SUBTOOL, DeleteSubtool);
}

export default function* rootSaga() {
  yield all([
    fork(CreateSubtoolSaga),
    fork(FetchSubtoolSaga),
    fork(PauseSubtoolSaga),
    fork(UpdateSubtoolSaga),
    fork(DeleteSubtoolSaga),
  ]);
}