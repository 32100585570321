import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
    Popover,
    colors,
} from '@material-ui/core'

import { ToolList, EmptyList } from './components'

const useStyles = makeStyles(() => ({
    root: {
        width: 250,
        maxWidth: '100%',
    },
    actions: {
        backgroundColor: colors.grey[50],
        justifyContent: 'center',
    },
}))

const ToolsPopover = (props) => {
    const { tools, anchorEl, onClose, ...rest } = props

    const classes = useStyles()

    return (
        <Popover
            {...rest}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
        >
            <div className={classes.root}>
                {tools.length > 0 ? (
                    <ToolList closePopOver={onClose} tools={tools} />
                ) : (
                        <EmptyList />
                    )}
            </div>
        </Popover>
    )
}

ToolsPopover.propTypes = {
    anchorEl: PropTypes.any,
    className: PropTypes.string,
    tools: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default ToolsPopover
