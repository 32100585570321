import Cookies from 'universal-cookie';

const getApiToken = () => {
    const cookies = new Cookies();
    return cookies.get('__session');
};

const serializeObject = (object, formData = new FormData(), prevKey) => {
    if (!(object && object.constructor === Object)) return formData;
    Object.keys(object).forEach(key => {
        const value = object[key];
        if (value && value.constructor === Object) {
            return serializeObject(value, formData, key);
        }
        if (prevKey) {
            key = `${prevKey}[${key}]`;
        }
        if (Array.isArray(value)) {
            value.forEach((val, index) => {
                if (val && val.constructor === Object) return serializeObject(val, formData, `${key}[${index}]`);
                formData.append(`${key}[${index}]`, val);
                // keeper[`${key}[${index}]`] = val;
            });
        } else {
            formData.append(`${key}`, value);
            // keeper[key] = value;
        }
    });
    return formData;
    // return keeper;
}

async function custom_fetch(options) {
    try {
        let { base_url, path, query, method, data } = options;
        let fd = serializeObject(data);
        if (!base_url) base_url = process.env.REACT_APP_API_URL + '/api/v1/admin'

        let headers = new Headers();
        let token = getApiToken();
        headers.append("Authorization", token ? `Bearer ${token}` : '');

        let settings = {
            method: method,
            body: fd,
            redirect: 'follow',
            headers: headers
        };

        if (!data) delete settings.body;

        let response = await fetch(base_url + path + (query || ''), settings)
            .then(async response => {
                if (response.ok || !response.status === 200) {
                    const json = await response.json();
                    if (json.success === false && json.error && json.error.authentication && token) {
                        window.location.href = "/auth/login";
                    }
                    return json;
                } else {
                    throw new Error('Something went wrong');
                }
            }).catch(e => ({
                error: { message: "A technical error occured" },
                success: false,
                meta: { status: 500 }
            }));

        // update cookie expiry to 15 minutes
        if (token) {
            const cookies = new Cookies();
            cookies.set('__session', token, { path: "/", expires: new Date(Date.now() + (1000 * 60 * 60)) });
        }

        return response;
    } catch (error) {
        return ({
            error: { message: "A technical error occured" },
            success: false,
            meta: { status: 500 }
        })
    }
}

export default custom_fetch;