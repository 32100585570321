import {
    ADD_DAYS_TO_USERS,
    ADD_DAYS_TO_USERS_SUCCESS,
    REMOVE_DAYS_FROM_USERS,
    REMOVE_DAYS_FROM_USERS_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
    updated: false,
    failedEmails: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_DAYS_TO_USERS: {
            return {
                ...state,
                updated: false,
            }
        }
        case ADD_DAYS_TO_USERS_SUCCESS: {
            return {
                ...state,
                updated: true,
                failedEmails: action.payload
            }
        }
        case REMOVE_DAYS_FROM_USERS: {
            return {
                ...state,
                updated: false,
            }
        }
        case REMOVE_DAYS_FROM_USERS_SUCCESS: {
            return {
                ...state,
                updated: true,
                failedEmails: action.payload
            }
        }
        default:
            return {
                ...state
            };
    }
}
