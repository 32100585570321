import {
  ASSIGN_SUBTOOL,
  ASSIGN_SUBTOOL_SUCCESS,
  SET_ACCESS_PERIOD,
  SET_ACCESS_PERIOD_SUCCESS,
  DISABLE_TOOL_FOR_USER,
  DISABLE_TOOL_FOR_USER_SUCCESS,
  ENABLE_TOOL_FOR_USER,
  ENABLE_TOOL_FOR_USER_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  assigned: false,
  access_period_changed: false,
  tool_disabled: false,
  tool_enabled: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ASSIGN_SUBTOOL: {
      return {
        ...state,
        assigned: false
      }
    }
    case ASSIGN_SUBTOOL_SUCCESS: {
      return {
        ...state,
        assigned: true
      }
    }
    case SET_ACCESS_PERIOD: {
      return {
        ...state,
        access_period_changed: false
      }
    }
    case SET_ACCESS_PERIOD_SUCCESS: {
      return {
        ...state,
        access_period_changed: true
      }
    }
    case DISABLE_TOOL_FOR_USER: {
      return {
        ...state,
        tool_disabled: false
      }
    }
    case DISABLE_TOOL_FOR_USER_SUCCESS: {
      return {
        ...state,
        tool_disabled: true
      }
    }
    case ENABLE_TOOL_FOR_USER: {
      return {
        ...state,
        tool_enabled: false
      }
    }
    case ENABLE_TOOL_FOR_USER_SUCCESS: {
      return {
        ...state,
        tool_enabled: true
      }
    }
    default:
      return {
        ...state
      };
  }
}
