import {
  FETCH_MY_PROFILE,
  FETCH_MY_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
} from 'constants/ActionTypes';

export const FetchMyProfile = (vitalForPage = false) => {
  return {
    type: FETCH_MY_PROFILE,
    vitalForPage: vitalForPage
  };
};

export const FetchMyProfileSuccess = (profile) => {
  return {
    type: FETCH_MY_PROFILE_SUCCESS,
    payload: profile
  };
};

export const UpdateProfile = (profile) => {
  return {
    type: UPDATE_PROFILE,
    payload: profile
  };
};

export const UpdateProfileSuccess = () => {
  return {
    type: UPDATE_PROFILE_SUCCESS
  };
};

export const UpdatePassword = (obj) => {
  return {
    type: UPDATE_PASSWORD,
    payload: obj
  };
};

export const UpdatePasswordSuccess = () => {
  return {
    type: UPDATE_PASSWORD_SUCCESS
  };
};
