import {
  FETCH_MY_PROFILE,
  FETCH_MY_PROFILE_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  fetching: false,
  ProfileUpdated: false,
  PasswordUpdated: false,
  Profile: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MY_PROFILE: {
      return {
        ...state,
        fetching: true,
      }
    }
    case FETCH_MY_PROFILE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        Profile: action.payload
      }
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        fetching: true,
        ProfileUpdated: false,
      }
    }
    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        ProfileUpdated: true,
      }
    }
    case UPDATE_PASSWORD: {
      return {
        ...state,
        fetching: true,
        PasswordUpdated: false,
      }
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        fetching: false,
        PasswordUpdated: true,
      }
    }
    default:
      return {
        ...state,
        PasswordUpdated: false,
        ProfileUpdated: false,
      };
  }
}
