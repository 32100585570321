import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_USER,
  FETCH_USERS,
  DELETE_USER,
  BAN_USER,
  CREATE_USER,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  PAUSE_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
} from "constants/ActionTypes";
import {
  CreateUserSuccess,
  FetchUserSuccess,
  FetchUsersSuccess,
  DeleteUserSuccess,
  BanUserSuccess,
  UpdateUserSuccess,
  UpdateUserPasswordSuccess,
  ShowNotificationAlert,
  FetchingPageDataSuccess,
  FetchingPageData,
  ShowErrorScreen,
  PauseSubscriptionSuccess,
  ResumeSubscriptionSuccess,
} from "actions";
import request from 'utils/connector';

const createUser = async (obj) =>
  await request({
    path: '/users',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const fetchUser = async (id) =>
  await request({
    path: '/user/' + id,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const fetchUsers = async (queryString) =>
  await request({
    path: '/users?' + queryString,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const updateUser = async (id, user) =>
  await request({
    path: '/users/' + id,
    method: 'POST',
    data: user,
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const updateUserPassword = async (id, password) =>
  await request({
    path: '/users/password-update/' + id,
    method: 'POST',
    data: password,
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const deleteUser = async (id) =>
  await request({
    path: '/user/' + id,
    method: 'DELETE'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const banUser = async (id) =>
  await request({
    path: '/user/ban/' + id,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const pauseSubscription = async (user_id) =>
  await request({
    path: '/pause-subscription/' + user_id,
    method: 'POST',
    data: {}
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const resumeSubscription = async (user_id) =>
  await request({
    path: '/resume-subscription/' + user_id,
    method: 'POST',
    data: {}
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* CreateUser({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Creating User" }));
    yield call(createUser, obj);
    yield put(CreateUserSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* FetchUser({ payload }) {
  const id = payload;
  try {
    yield put(FetchingPageData());

    const user = yield call(fetchUser, id);
    yield put(FetchUserSuccess(user));

    yield put(FetchingPageDataSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

function* FetchUsers({ payload }) {
  const { page, searchString, rowsPerPage } = payload;

  const queryString = [
    "page=" + (page ? page : ""),
    "search=" + (searchString ? searchString : ""),
    "count=" + (rowsPerPage ? rowsPerPage : "")
  ].join("&");

  try {
    const users = yield call(fetchUsers, queryString);
    yield put(FetchUsersSuccess(users));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* UpdateUser({ payload }) {
  const { id, user } = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Updating User" }));
    const response = yield call(updateUser, id, user);
    yield put(UpdateUserSuccess(response));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* UpdateUserPassword({ payload }) {
  const { id, password } = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Updating User Password" }));
    yield call(updateUserPassword, id, password);
    yield put(UpdateUserPasswordSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* DeleteUser({ payload }) {
  const id = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Deleting User" }));
    yield call(deleteUser, id);
    yield put(DeleteUserSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* BanUser({ payload }) {
  const { id, banned } = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: banned ? "Lifting ban..." : "Banning User" }));
    yield call(banUser, id);
    yield put(BanUserSuccess(!banned));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* PauseSubscription({ payload }) {
  const { user_id } = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Pausing User Subscription..." }));
    yield call(pauseSubscription, user_id);
    yield put(PauseSubscriptionSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* ResumeSubscription({ payload }) {
  const { user_id } = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Enabling User Subscription..." }));
    yield call(resumeSubscription, user_id);
    yield put(ResumeSubscriptionSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* CreateUserSaga() {
  yield takeEvery(CREATE_USER, CreateUser);
}

export function* FetchUserSaga() {
  yield takeEvery(FETCH_USER, FetchUser);
}

export function* FetchUsersSaga() {
  yield takeEvery(FETCH_USERS, FetchUsers);
}

export function* UpdateUserSaga() {
  yield takeEvery(UPDATE_USER, UpdateUser);
}

export function* UpdateUserPasswordSaga() {
  yield takeEvery(UPDATE_USER_PASSWORD, UpdateUserPassword);
}

export function* DeleteUserSaga() {
  yield takeEvery(DELETE_USER, DeleteUser);
}

export function* BanUserSaga() {
  yield takeEvery(BAN_USER, BanUser);
}

export function* PauseSubscriptionSaga() {
  yield takeEvery(PAUSE_SUBSCRIPTION, PauseSubscription);
}

export function* ResumeSubscriptionSaga() {
  yield takeEvery(RESUME_SUBSCRIPTION, ResumeSubscription);
}

export default function* rootSaga() {
  yield all([
    fork(CreateUserSaga),
    fork(FetchUserSaga),
    fork(FetchUsersSaga),
    fork(UpdateUserSaga),
    fork(UpdateUserPasswordSaga),
    fork(DeleteUserSaga),
    fork(BanUserSaga),
    fork(PauseSubscriptionSaga),
    fork(ResumeSubscriptionSaga),
  ]);
}