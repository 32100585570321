import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
    title: {
        display: 'table-cell',
        verticalAlign: 'middle',
        fontSize: '1.3rem',
        fontWeight: 500,
        fontFamily: 'Roboto',
        marginBottom: 0
    },
}))

const ToolTitle = (props) => {
    const { tool } = props;

    const classes = useStyles()

    return (
        <h3 className={classes.title}>
            {tool.name}
        </h3>
    )
}

export default ToolTitle
