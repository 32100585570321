import {
  PEEK_TOOLS,
  PEEK_TOOLS_SUCCESS,
  FETCH_TOOLS,
  FETCH_TOOLS_SUCCESS,
  FETCH_TOOL,
  FETCH_TOOL_SUCCESS,
  DELETE_TOOL,
  DELETE_TOOL_SUCCESS,
  UPDATE_TOOL,
  UPDATE_TOOL_SUCCESS,
  NEW_TOOL,
  NEW_TOOL_SUCCESS,
} from 'constants/ActionTypes';

export const PeekTools = () => {
  return {
    type: PEEK_TOOLS
  };
};

export const PeekToolsSuccess = (tools) => {
  return {
    type: PEEK_TOOLS_SUCCESS,
    payload: tools
  };
};

export const FetchTools = (page, searchString, rowsPerPage) => {
  return {
    type: FETCH_TOOLS,
    payload: { page, searchString, rowsPerPage }
  };
};

export const FetchToolsSuccess = (tools) => {
  return {
    type: FETCH_TOOLS_SUCCESS,
    payload: tools
  };
};

export const FetchTool = (id) => {
  return {
    type: FETCH_TOOL,
    payload: id
  };
};

export const FetchToolSuccess = (tool) => {
  return {
    type: FETCH_TOOL_SUCCESS,
    payload: tool
  };
};

export const DeleteTool = (obj) => {
  return {
    type: DELETE_TOOL,
    payload: obj
  };
};

export const DeleteToolSuccess = () => {
  return {
    type: DELETE_TOOL_SUCCESS
  };
};

export const UpdateTool = (obj) => {
  return {
    type: UPDATE_TOOL,
    payload: obj
  };
};

export const UpdateToolSuccess = (tool) => {
  return {
    type: UPDATE_TOOL_SUCCESS,
    payload: tool
  };
};

export const NewTool = (obj) => {
  return {
    type: NEW_TOOL,
    payload: obj
  };
};

export const NewToolSuccess = () => {
  return {
    type: NEW_TOOL_SUCCESS
  };
};
