import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  UPDATE_BRIEFS
} from "constants/ActionTypes";
import Cookies from 'universal-cookie';

const INIT_STATE = {
  loading: false,
  reset_password_success: false,
  signin_success: false,
  authUser: (new Cookies()).get('__session'),
  brief_info: { user: {} },
  brief_loaded: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
        reset_password_success: false,
      }
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        reset_password_success: true
      }
    }
    case SIGNIN: {
      return {
        ...state,
        loading: true,
        signin_success: false,
      }
    }
    case SIGNIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        signin_success: true,
        authUser: action.payload
      }
    }
    case SIGNOUT: {
      return {
        ...state,
        loading: false,
        signin_success: false,
        authUser: null,
      }
    }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        signin_success: false,
        authUser: null,
      }
    }
    case UPDATE_BRIEFS: {
      return {
        ...state,
        brief_info: action.payload,
        brief_loaded: true
      }
    }
    default:
      return {
        ...state,
        loading: false,
        reset_password_success: false,
        signin_success: false
      };
  }
}
