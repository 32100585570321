import {
  SHOW_NOTIFICATION_ALERT
} from 'constants/ActionTypes';

const INIT_STATE = {
  open: false,
  type: "",
  message: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION_ALERT: {
      return {
        ...state,
        open: true,
        type: action.payload.type,
        message: action.payload.message
      }
    }
    default:
      return {
        ...state,
        open: false,
        type: "",
        message: ""
      };
  }
}
