import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined'
import AppsOutlined from '@material-ui/icons/AppsOutlined'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

export default [
    {
        title: 'Pages',
        pages: [
            {
                title: 'Manage Tools',
                href: '/tools',
                icon: AppsOutlined
            },
            {
                title: 'Manage Users',
                href: '/users',
                icon: AccountCircleOutlined
            },
            {
                title: 'Verify Payment',
                href: '/verify-payment',
                icon: PaymentOutlinedIcon,
            },
            {
                title: 'Add Expiry Interval',
                href: '/add-expiry-interval',
                icon: ScheduleOutlinedIcon,
            },
            {
                title: 'Bonus',
                href: '/bonus',
                icon: AccountBalanceWalletOutlinedIcon,
            },
            {
                title: 'Clear Subscriptions',
                href: '/remove-days-from-users',
                icon: CancelOutlinedIcon,
            },
        ],
    },
]
