import {
  SHOW_MODAL_ALERT,
  HIDE_MODAL_ALERT,
} from 'constants/ActionTypes';

const INIT_STATE = {
  open: false,
  title: "",
  message: "",
  buttons: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_MODAL_ALERT: {
      return {
        ...state,
        open: true,
        title: action.payload.title,
        message: action.payload.message,
        buttons: action.payload.buttons
      }
    }
    case HIDE_MODAL_ALERT: {
      return {
        ...state,
        open: false,
        title: "",
        message: "",
        buttons: []
      }
    }
    default:
      return {
        ...state,
        open: false,
        title: "",
        message: "",
        buttons: []
      };
  }
}
