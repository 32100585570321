import React from 'react';
import { useSelector } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NotificationAlert = (props) => {
  const settings = useSelector(({ NotificationAlert }) => NotificationAlert)

  return (
    <>
      {settings.open && settings.type === "error" && setTimeout(() => {
        NotificationManager.error(settings.message)
      }, 100) && null}
      {settings.open && settings.type === "info" && setTimeout(() => {
        NotificationManager.info(settings.message)
      }, 100) && null}
      {settings.open && settings.type === "warning" && setTimeout(() => {
        NotificationManager.warning(settings.message)
      }, 100) && null}
      {settings.open && settings.type === "success" && setTimeout(() => {
        NotificationManager.success(settings.message)
      }, 100) && null}
      < NotificationContainer />
    </>
  );
}

export default NotificationAlert;
