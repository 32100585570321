import {
  FETCHING_PAGE_DATA,
  FETCHING_PAGE_DATA_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  fetching: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCHING_PAGE_DATA: {
      return {
        ...state,
        fetching: true
      }
    }
    case FETCHING_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        fetching: false
      }
    }
    default:
      return {
        ...state
      };
  }
}
