import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader
} from '@material-ui/core'
import useRouter from 'utils/useRouter'

const Links = [
    {
        type: 'group',
        name: 'Quick Links',
        links: [
            {
                name: "Dashboard",
                to: "/dashboard"
            },
            {
                name: "Manage Payments",
                to: "/payments"
            },
            {
                name: "My Profile",
                to: "/profile"
            }
        ]
    },
    {
        type: 'group',
        name: 'Support',
        links: [
            {
                name: "Email Support",
                to: "mailto:support@databycloud.com"
            }
        ]
    }
]

const useStyles = makeStyles((theme) => ({
    root: {},
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
    },
    listSection: {
        backgroundColor: 'inherit',

    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    subheaderName: {
        fontSize: 12,
        borderBottom: '1px solid #eeeeee',
        textTransform: 'uppercase',
        lineHeight: 'initial',
        paddingTop: 12,
        paddingBottom: 12
    },
    listText: {
        '&>span': {
            fontSize: 14
        }
    }
}))

const QuickLinksList = (props) => {
    let { className, closePopOver, ...rest } = props;

    const classes = useStyles()

    const router = useRouter();

    const handleClick = (url) => {
        closePopOver();
        if (url.indexOf('mailto') === 0) {
            window.open(url, '_self')
            return
        }
        router.history.push(url)
    }

    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
            disablePadding
            subheader={<li />}
        >
            {Links.map((section, index) => (
                <li key={index} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader className={classes.subheaderName}>{section.name}</ListSubheader>
                        {
                            section.links.map((link, index) => (
                                <ListItem
                                    className={classes.listItem}
                                    // component={RouterLink}
                                    divider={index < section.links.length}
                                    key={index}
                                    onClick={() => handleClick(link.to)}
                                >
                                    <ListItemText
                                        primary={link.name}
                                        primaryTypographyProps={{ variant: 'body1' }}
                                        className={classes.listText}
                                    />
                                </ListItem>
                            ))
                        }
                    </ul>
                </li>
            ))}
        </List>
    )
}

QuickLinksList.propTypes = {
    className: PropTypes.string
}

export default QuickLinksList
