import {
  ASSIGN_SUBTOOL,
  ASSIGN_SUBTOOL_SUCCESS,
  SET_ACCESS_PERIOD,
  SET_ACCESS_PERIOD_SUCCESS,
  DISABLE_TOOL_FOR_USER,
  DISABLE_TOOL_FOR_USER_SUCCESS,
  ENABLE_TOOL_FOR_USER,
  ENABLE_TOOL_FOR_USER_SUCCESS,
} from 'constants/ActionTypes';

export const AssignSubtool = (obj) => {
  return {
    type: ASSIGN_SUBTOOL,
    payload: obj
  };
};

export const AssignSubtoolSuccess = () => {
  return {
    type: ASSIGN_SUBTOOL_SUCCESS
  };
};

export const SetAccessPeriod = (obj) => {
  return {
    type: SET_ACCESS_PERIOD,
    payload: obj
  };
};

export const SetAccessPeriodSuccess = () => {
  return {
    type: SET_ACCESS_PERIOD_SUCCESS
  };
};

export const DisableToolForUser = (obj) => {
  return {
    type: DISABLE_TOOL_FOR_USER,
    payload: obj
  };
};

export const DisableToolForUserSuccess = () => {
  return {
    type: DISABLE_TOOL_FOR_USER_SUCCESS
  };
};

export const EnableToolForUser = (obj) => {
  return {
    type: ENABLE_TOOL_FOR_USER,
    payload: obj
  };
};

export const EnableToolForUserSuccess = () => {
  return {
    type: ENABLE_TOOL_FOR_USER_SUCCESS
  };
};