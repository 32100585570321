import {
  ADD_DAYS_TO_USERS,
  ADD_DAYS_TO_USERS_SUCCESS,
  REMOVE_DAYS_FROM_USERS,
  REMOVE_DAYS_FROM_USERS_SUCCESS,
} from 'constants/ActionTypes';

export const AddDaysToUsers = (obj) => {
  return {
    type: ADD_DAYS_TO_USERS,
    payload: obj
  };
};

export const AddDaysToUsersSuccess = (failedEmails) => {
  return {
    type: ADD_DAYS_TO_USERS_SUCCESS,
    payload: failedEmails
  };
};

export const RemoveDaysFromUsers = (obj) => {
  return {
    type: REMOVE_DAYS_FROM_USERS,
    payload: obj
  };
};

export const RemoveDaysFromUsersSuccess = (failedEmails) => {
  return {
    type: REMOVE_DAYS_FROM_USERS_SUCCESS,
    payload: failedEmails
  };
};