import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  RESET_PASSWORD,
  SIGNIN,
  SIGNOUT,
  FETCH_BRIEFS
} from "constants/ActionTypes";
import { ResetPasswordSuccess, SignInSuccess, SignOutSuccess, UpdateBriefs, ShowNotificationAlert, ShowErrorScreen } from "actions";
import request from 'utils/connector';
import Cookies from 'universal-cookie';

const resetPasswordRequest = async (email) =>
  await request({
    path: '/auth/reset-password',
    method: 'POST',
    data: { email }
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
  });

const signInRequest = async (email, password) =>
  await request({
    path: '/auth/login',
    method: 'POST',
    data: { email, password }
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.token;
  });

const signOutRequest = async () =>
  await request({
    path: '/auth/logout',
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
  });

const fetchBriefInfoRequest = async () =>
  await request({
    path: '/auth/brief-info',
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data;
  });

function* resetPassword({ payload }) {
  const email = payload;
  try {
    yield call(resetPasswordRequest, email);
    yield put(ShowNotificationAlert({ type: "success", message: "Password reset is successful" }));
    yield put(ResetPasswordSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* signIn({ payload }) {
  const { email, password } = payload;
  try {
    let token = yield call(signInRequest, email, password);
    if (token && token.token) {
     const cookies = new Cookies();
      cookies.set('__session', token.token, { path: "/", expires: new Date(Date.now() + (1000 * 60 * 60)) });
      yield put(SignInSuccess(token.token)); 
    } else {
      yield put(ShowNotificationAlert({ type: "error", message: "Login Failed" }));
    }
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* signOut() {
  try {
    const cookies = new Cookies();
    cookies.remove('__session', { path: "/" });
    yield call(signOutRequest);
    yield put(SignOutSuccess());
  } catch (error) {
    // yield put(ShowNotificationAlert({ type: "error", message: "Signout Failed" }));
  }
}

function* fetchBriefInfo() {
  try {
    let brief_info = yield call(fetchBriefInfoRequest);
    yield put(UpdateBriefs(brief_info));
  } catch (error) {
    // yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

export function* resetPasswordSaga() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* signInSaga() {
  yield takeEvery(SIGNIN, signIn);
}

export function* signOutSaga() {
  yield takeEvery(SIGNOUT, signOut);
}

export function* fetchBriefInfoSaga() {
  yield takeEvery(FETCH_BRIEFS, fetchBriefInfo);
}

export default function* rootSaga() {
  yield all([fork(resetPasswordSaga), fork(signInSaga), fork(signOutSaga), fork(fetchBriefInfoSaga)]);
}