import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { Page } from 'components'
import {
    WelcomeBanner,
    Tools,
    ThingsToNote
} from './components'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        width: '90%',
        maxWidth: '1100px',
        padding: 30
    },
    container: {
        marginTop: theme.spacing(1),
    },
}))

const Dashboard = () => {
    const classes = useStyles();

    return (
        <Page className={classes.root} title="Dashboard">

            <WelcomeBanner />

            <Tools />

            <ThingsToNote />

        </Page>
    )
}

export default Dashboard
