import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button, useTheme, useMediaQuery } from '@material-ui/core'

import { Page } from 'components'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        paddingTop: '10vh',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
    },
    imageContainer: {
        marginTop: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        maxWidth: '100%',
        width: 560,
        maxHeight: 300,
        height: 'auto',
    },
    buttonContainer: {
        marginTop: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
    },
}))

const ErrorScreen = () => {
    const classes = useStyles()
    const theme = useTheme()
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Page className={classes.root} title="Oops! Something went wrong">
            <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
                500: Ooops, something went terribly wrong!
            </Typography>
            <Typography align="center" variant="subtitle2">
                Reload the page. If problem persists, kindly get accross to our team at &nbsp;
                <a href={"mailto:support@databycloud.com"}>support@databycloud.com</a>
            </Typography>
            <div className={classes.imageContainer}>
                <img
                    alt="Under development"
                    className={classes.image}
                    src="/images/undraw_server_down_s4lk.svg"
                />
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    color="primary"
                    component={RouterLink}
                    onClick={function () {
                        window.location.reload();
                    }}
                    variant="outlined">
                    Reload Page
                </Button>
            </div>
        </Page>
    )
}

export default ErrorScreen
