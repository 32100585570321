import React, { Fragment, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { SignOutSuccess } from 'actions'
import { useDispatch } from 'react-redux'
import useRouter from './useRouter';

const SessionExpired = () => {
    const dispatch = useDispatch();

    const router = useRouter();

    // log user out if cookie expires
    useEffect(() => {
        setInterval(() => {
            const cookies = new Cookies();
            const token = cookies.get('__session', { path: "/" });
            if (!token && router.history.location.pathname !== '/auth/login') {
                dispatch(SignOutSuccess());
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<Fragment />)
}

export default SessionExpired
