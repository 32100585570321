import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ToolCard } from './components'

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20
    },
}))

const Tools = (props) => {
    const BriefData = useSelector(({ Auth }) => Auth.brief_info);

    const { tools, tool_details } = BriefData;

    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={2}>
                    {
                        tools.map((tool, index) => {
                            let tool_detail = tool_details.tools.filter(e => e.id === tool.id)[0],
                                active = tool_detail ? tool_detail.active : 0;

                            return (
                                <ToolCard
                                    key={index}
                                    tool={tool}
                                    active={active}
                                />
                            )
                        })
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Tools
