import {
    CREATE_EXPIRY_INTERVAL,
    CREATE_EXPIRY_INTERVAL_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    created: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_EXPIRY_INTERVAL: {
            return {
                ...state,
                created: false,
            }
        }
        case CREATE_EXPIRY_INTERVAL_SUCCESS: {
            return {
                ...state,
                created: true,
            }
        }
        default:
            return {
                ...state
            };
    }
}
