import {
  FETCHING_PAGE_DATA,
  FETCHING_PAGE_DATA_SUCCESS
} from 'constants/ActionTypes';

export const FetchingPageData = () => {
  return {
    type: FETCHING_PAGE_DATA
  };
};

export const FetchingPageDataSuccess = () => {
  return {
    type: FETCHING_PAGE_DATA_SUCCESS
  };
};