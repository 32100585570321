import {
  FETCH_TRANSACTION_ID,
  FETCH_TRANSACTION_ID_SUCCESS,
  VERIFY_PAYMENT,
  VERIFY_PAYMENT_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  fetched: false,
  transaction: {},
  verified: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_ID: {
      return {
        ...state,
        fetched: false
      }
    }
    case FETCH_TRANSACTION_ID_SUCCESS: {
      return {
        ...state,
        fetched: true,
        transaction: action.payload
      }
    }
    case VERIFY_PAYMENT: {
      return {
        ...state,
        verified: false
      }
    }
    case VERIFY_PAYMENT_SUCCESS: {
      return {
        ...state,
        verified: true,
      }
    }
    default:
      return {
        ...state,
        TransactionIdReady: false,
      };
  }
}
