import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_TRANSACTION_ID,
  VERIFY_PAYMENT,
} from "constants/ActionTypes";
import {
  FetchTransactionIdSuccess,
  VerifyPaymentSuccess,
  ShowNotificationAlert,
} from "actions";
import request from 'utils/connector';

const fetchTransactionId = async (transaction_id) =>
  await request({
    path: '/payments?transaction_id=' + transaction_id,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const verifyPayment = async (obj) =>
  await request({
    path: '/payments',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* FetchTransactionId({ payload }) {
  const transaction_id = payload;

  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Fetching Transaction" }));
    const transaction = yield call(fetchTransactionId, transaction_id);
    yield put(FetchTransactionIdSuccess(transaction));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* VerifyPayment({ payload }) {
  const obj = payload;

  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Verifying Transaction" }));
    yield call(verifyPayment, obj);
    yield put(VerifyPaymentSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* FetchTransactionIdSaga() {
  yield takeEvery(FETCH_TRANSACTION_ID, FetchTransactionId);
}

export function* VerifyPaymentSaga() {
  yield takeEvery(VERIFY_PAYMENT, VerifyPayment);
}

export default function* rootSaga() {
  yield all([
    fork(FetchTransactionIdSaga),
    fork(VerifyPaymentSaga),
  ]);
}