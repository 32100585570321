import {
  PEEK_TOOLS,
  PEEK_TOOLS_SUCCESS,
  FETCH_TOOLS,
  FETCH_TOOLS_SUCCESS,
  FETCH_TOOL,
  FETCH_TOOL_SUCCESS,
  DELETE_TOOL,
  DELETE_TOOL_SUCCESS,
  UPDATE_TOOL,
  UPDATE_TOOL_SUCCESS,
  NEW_TOOL,
  NEW_TOOL_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  fetching: false,
  deleted: false,
  updated: false,
  created: false,
  tools: [],
  tool: { subtools: [] },
  Tools: { total: 1, perPage: 5, page: 1, lastPage: 1, data: [] },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PEEK_TOOLS: {
      return {
        ...state,
        fetching: true
      }
    }
    case PEEK_TOOLS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        tools: action.payload
      }
    }
    case FETCH_TOOLS: {
      return {
        ...state,
        fetching: true
      }
    }
    case FETCH_TOOLS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        Tools: action.payload
      }
    }
    case FETCH_TOOL: {
      return {
        ...state,
        fetching: true
      }
    }
    case FETCH_TOOL_SUCCESS: {
      return {
        ...state,
        fetching: false,
        tool: action.payload
      }
    }
    case DELETE_TOOL: {
      return {
        ...state,
        deleted: false
      }
    }
    case DELETE_TOOL_SUCCESS: {
      return {
        ...state,
        deleted: true
      }
    }
    case UPDATE_TOOL: {
      return {
        ...state,
        updated: false
      }
    }
    case UPDATE_TOOL_SUCCESS: {
      return {
        ...state,
        updated: true,
        tool: {
          ...state.tool,
          ...action.payload
        }
      }
    }
    case NEW_TOOL: {
      return {
        ...state,
        created: false
      }
    }
    case NEW_TOOL_SUCCESS: {
      return {
        ...state,
        created: true
      }
    }
    default:
      return {
        ...state
      };
  }
}
