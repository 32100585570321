import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ASSIGN_SUBTOOL,
  SET_ACCESS_PERIOD,
  DISABLE_TOOL_FOR_USER,
  ENABLE_TOOL_FOR_USER,
} from "constants/ActionTypes";
import {
  AssignSubtoolSuccess,
  SetAccessPeriodSuccess,
  DisableToolForUserSuccess,
  EnableToolForUserSuccess,
  ShowNotificationAlert,
} from "actions";
import request from 'utils/connector';

const assignSubtool = async (obj) =>
  await request({
    path: '/user/assign-subtool',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const setAccessPeriod = async (obj) =>
  await request({
    path: '/user/set-access-period',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const enableToolForUser = async (obj) =>
  await request({
    path: '/user/enable-tool',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const disableToolForUser = async (obj) =>
  await request({
    path: '/user/disable-tool',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* AssignSubtool({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Assigning Subtool" }));
    yield call(assignSubtool, obj);
    yield put(AssignSubtoolSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* SetAccessPeriod({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Setting Access Period" }));
    yield call(setAccessPeriod, obj);
    yield put(SetAccessPeriodSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* DisableToolForUser({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Setting Rule" }));
    yield call(disableToolForUser, obj);
    yield put(DisableToolForUserSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* EnableToolForUser({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Setting Rule" }));
    yield call(enableToolForUser, obj);
    yield put(EnableToolForUserSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* AssignSubtoolSaga() {
  yield takeEvery(ASSIGN_SUBTOOL, AssignSubtool);
}

export function* SetAccessPeriodSaga() {
  yield takeEvery(SET_ACCESS_PERIOD, SetAccessPeriod);
}

export function* DisableToolForUserSaga() {
  yield takeEvery(DISABLE_TOOL_FOR_USER, DisableToolForUser);
}

export function* EnableToolForUserSaga() {
  yield takeEvery(ENABLE_TOOL_FOR_USER, EnableToolForUser);
}

export default function* rootSaga() {
  yield all([
    fork(AssignSubtoolSaga),
    fork(SetAccessPeriodSaga),
    fork(DisableToolForUserSaga),
    fork(EnableToolForUserSaga),
  ]);
}