import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Typography } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt'

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginBottom: 10,
    },
    howToUse: {
        display: 'flex',
        marginBottom: 10,
        fontWeight: 400,
        flexWrap: 'wrap'
        // color: '#000000'
    },
    howToUseGuideText: {
        color: '#000000',
    },
    howToUseGuideTextInnerFlex: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    mr1: {
        marginRight: theme.spacing(1),
    },
}))

const WelcomeBanner = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" variant="h3" gutterBottom>
                    Welcome Johnson
                </Typography>
                <Typography className={classes.howToUse} variant="h4">
                    <span className={classes.mr1}>How To Use:</span>
                    <span className={classes.howToUseGuideText}>
                        <span className={classes.howToUseGuideTextInnerFlex}>
                            Purchase Tool
                            <ArrowRightIcon />
                            Install Plugins
                            <ArrowRightIcon />
                            Access Tool
                        </span>
                    </span>
                </Typography>
            </CardContent>
        </Card>
    )
}

WelcomeBanner.propTypes = {
    className: PropTypes.string,
}

WelcomeBanner.defaultProps = {}

export default WelcomeBanner
