import { combineReducers } from 'redux'

import authReducer from './Auth'
import bonusReducer from './Bonus'
import errorScreenReducer from './ErrorScreen'
import expiryIntervalReducer from './ExpiryInterval'
import modalAlertReducer from './ModalAlert'
import notificationAlertReducer from './NotificationAlert'
import pageReducer from './Page'
import paymentReducer from './Payment'
import profileReducer from './Profile'
import sessionReducer from './sessionReducer'
import subtoolReducer from './Subtool'
import toolReducer from './Tool'
import userReducer from './User'
import userToolReducer from './UserTool'

const rootReducer = combineReducers({
    Auth: authReducer,
    Bonus: bonusReducer,
    ErrorScreen: errorScreenReducer,
    ExpiryInterval: expiryIntervalReducer,
    ModalAlert: modalAlertReducer,
    NotificationAlert: notificationAlertReducer,
    Page: pageReducer,
    Payment: paymentReducer,
    Profile: profileReducer,
    session: sessionReducer,
    Subtool: subtoolReducer,
    Tool: toolReducer,
    User: userReducer,
    UserTool: userToolReducer,
})

export default rootReducer
