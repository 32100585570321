import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import { ToolImage, ToolTitle } from '..'
import useRouter from 'utils/useRouter'

const useStyles = makeStyles(() => ({
    header: {
        borderBottom: '1px solid #ebedf2',
        padding: '12px 20px'
    },
    content: {
        paddingBottom: 0,
    },
    properties: {
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: '0.5rem',
        lineHeight: '1.1',
        fontFamily: 'Roboto'
    }
}))

const ToolCard = (props) => {
    const { tool, active } = props

    const classes = useStyles()

    const router = useRouter()

    const navigateToTool = (alias) => {
        router.history.push('/tool/' + alias)
    }

    return (
        <Grid item lg={4} md={6} sm={12} xs={12}>
            <Card>
                <CardHeader
                    classes={{
                        root: classes.header
                    }}
                    avatar={<ToolImage tool={tool} />}
                    title={<ToolTitle tool={tool} />}
                />
                <CardContent classes={{
                    root: classes.content
                }}>
                    <Typography variant="h5" color="textSecondary" className={classes.properties}>Amount: ${Number(tool.price).toFixed(2)}</Typography>
                    <Typography variant="h5" color="textSecondary" className={classes.properties}>Active: {active ? 'Yes' : 'No'}</Typography>
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={() => navigateToTool(tool.alias)}
                    >
                        Access Tool
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default ToolCard
