import validate from 'validate.js'

const regex = (value, options) => {
    const regExp = new RegExp(options.pattern)

    if (!regExp.test(value)) {
        return options.message
    }
}

const checked = (value, options) => {
    if (value !== true) {
        return options.message || 'must be checked'
    }
}

const Cookies = (value) => {
    if (!value) return;
    try {
        value = JSON.parse(value);
        if (value.constructor === Array) {
            let inappropriateCookieObjects = value.filter(e => e.constructor !== Object);
            if (inappropriateCookieObjects.length > 0) return "is not a valid Cookie";
            return null;
        }
        return "is not a valid Cookie"
    } catch (error) {
        return "is not a valid Cookie"
    }
};

const RequiredIf = (value, options, key, attributes) => {
    if (options && options.dependencies && options.dependencies.constructor === Array) {
        if (options.dependencies.length > 0) {
            for (const dependency of options.dependencies) {
                if (dependency && attributes[dependency] && !value) {
                    const fieldName = options.names && options.names[dependency] ? options.names[dependency] : dependency;
                    return "is required if \"" + fieldName + "\" is supplied"
                }
            }
        }
    }
};

const RequiredIfNot = (value, options, key, attributes) => {
    if (options && options.dependencies && options.dependencies.constructor === Array) {
        if (options.dependencies.length > 0) {
            for (const dependency of options.dependencies) {
                if (dependency && !attributes[dependency] && !value) {
                    const fieldName = options.names && options.names[dependency] ? options.names[dependency] : dependency;
                    return "is required if \"" + fieldName + "\" is not supplied"
                }
            }
        }
    }
};

const emails = (value, options, key, attributes) => {
    if (!value) return
    const emails = value.split(",").map(e => e.trim());
    // eslint-disable-next-line no-control-regex
    const PATTERN = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    for (const email of emails) {
        if (!PATTERN.exec(email)) {
            return "is not a valid list of emails"
        }
    }
};

const IsDate = (value, options, key, attributes) => {
    if (!value) return
    if (value && value.constructor === Date) return;
    return "is not a date"
};

const file = (value) => {
    if (value && value.constructor === File) return;
    return "is not a file";
};

validate.isDefined = function (obj) {
    return obj !== null && obj !== undefined && obj !== "";
};

validate.validators = {
    ...validate.validators,
    regex,
    checked,
    Cookies,
    RequiredIf,
    RequiredIfNot,
    IsDate,
    file,
    emails,
}
