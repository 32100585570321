import {
  SHOW_MODAL_ALERT,
  HIDE_MODAL_ALERT
} from 'constants/ActionTypes';

export const ShowModalAlert = ({ open, title, message, buttons }) => {
  return {
    type: SHOW_MODAL_ALERT,
    payload: { open, title, message, buttons }
  };
};

export const HideModalAlert = () => {
  return {
    type: HIDE_MODAL_ALERT
  };
};
