import {
  CREATE_SUBTOOL,
  CREATE_SUBTOOL_SUCCESS,
  FETCH_SUBTOOL,
  FETCH_SUBTOOL_SUCCESS,
  PAUSE_SUBTOOL,
  PAUSE_SUBTOOL_SUCCESS,
  UPDATE_SUBTOOL,
  UPDATE_SUBTOOL_SUCCESS,
  DELETE_SUBTOOL,
  DELETE_SUBTOOL_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  paused: false,
  deleted: false,
  updated: false,
  created: false,
  fetched: false,
  subtool: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_SUBTOOL: {
      return {
        ...state,
        created: false
      }
    }
    case CREATE_SUBTOOL_SUCCESS: {
      return {
        ...state,
        created: true
      }
    }
    case FETCH_SUBTOOL: {
      return {
        ...state,
        fetched: false
      }
    }
    case FETCH_SUBTOOL_SUCCESS: {
      return {
        ...state,
        fetched: true,
        subtool: action.payload
      }
    }
    case PAUSE_SUBTOOL: {
      return {
        ...state,
        paused: false
      }
    }
    case PAUSE_SUBTOOL_SUCCESS: {
      return {
        ...state,
        paused: true
      }
    }
    case UPDATE_SUBTOOL: {
      return {
        ...state,
        updated: false
      }
    }
    case UPDATE_SUBTOOL_SUCCESS: {
      return {
        ...state,
        updated: true
      }
    }
    case DELETE_SUBTOOL: {
      return {
        ...state,
        deleted: false
      }
    }
    case DELETE_SUBTOOL_SUCCESS: {
      return {
        ...state,
        deleted: true
      }
    }
    default:
      return {
        ...state
      };
  }
}
