import {
  CREATE_EXPIRY_INTERVAL,
  CREATE_EXPIRY_INTERVAL_SUCCESS
} from 'constants/ActionTypes';

export const CreateExpiryInterval = (obj) => {
  return {
    type: CREATE_EXPIRY_INTERVAL,
    payload: obj
  };
};

export const CreateExpiryIntervalSuccess = () => {
  return {
    type: CREATE_EXPIRY_INTERVAL_SUCCESS
  };
};