import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Ws from '@adonisjs/websocket-client'
import { HideModalAlert, ShowModalAlert, SignOut } from 'actions';

const Websocket = () => {
    const AuthState = useSelector(({ Auth }) => Auth);

    const dispatch = useDispatch();

    useEffect(() => {
        const ws = Ws(process.env.REACT_APP_SOCKET_URL);

        let isConnected = false

        ws.withApiToken(AuthState.authUser).connect();

        ws.on('open', () => {
            isConnected = true

            const chat = ws.subscribe('databycloud')

            chat.on('ready', () => {
                chat.emit('message', { init: true })
            })

            chat.on('message', (data) => {
                if (data.logout) {
                    dispatch(ShowModalAlert({
                        open: true,
                        title: "Logged Out",
                        message: "Your session has ended",
                        buttons: [
                            {
                                text: "Login",
                                onClick: () => {
                                    dispatch(SignOut());
                                    dispatch(HideModalAlert());
                                }
                            }
                        ]
                    }))
                }
                // console.log(data);
            })

            chat.on('error', (error) => {
                // console.log(error)
            })

            chat.on('close', () => { })
        })

        ws.on('close', () => {
            isConnected = false;
        })
        return () => isConnected && ws.close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AuthState.authUser])

    return (<Fragment />)
}

export default Websocket
