import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  PEEK_TOOLS,
  FETCH_TOOLS,
  FETCH_TOOL,
  DELETE_TOOL,
  UPDATE_TOOL,
  NEW_TOOL,
} from "constants/ActionTypes";
import {
  PeekToolsSuccess,
  FetchToolsSuccess,
  FetchToolSuccess,
  DeleteToolSuccess,
  UpdateToolSuccess,
  NewToolSuccess,
  ShowNotificationAlert,
  FetchingPageDataSuccess,
  FetchingPageData,
  ShowErrorScreen,
} from "actions";
import request from 'utils/connector';

const peekTools = async () =>
  await request({
    path: '/tools?peek=true',
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const fetchTools = async (queryString) =>
  await request({
    path: '/tools?' + queryString,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const fetchTool = async (id) =>
  await request({
    path: '/tools/' + id,
    method: 'GET'
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const deleteTool = async (obj) =>
  await request({
    path: '/tools/delete',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const updateTool = async (obj) =>
  await request({
    path: '/tools',
    method: 'PUT',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

const newTool = async (obj) =>
  await request({
    path: '/tools',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* PeekTools() {
  try {
    yield put(FetchingPageData());

    const tools = yield call(peekTools);
    yield put(PeekToolsSuccess(tools));

    yield put(FetchingPageDataSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

function* FetchTools({ payload }) {
  const { page, searchString, rowsPerPage } = payload;

  const queryString = [
    "page=" + (page ? page : ""),
    "search=" + (searchString ? searchString : ""),
    "count=" + (rowsPerPage ? rowsPerPage : "")
  ].join("&");

  try {
    const tools = yield call(fetchTools, queryString);
    yield put(FetchToolsSuccess(tools));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* FetchTool({ payload }) {
  const id = payload;
  try {
    yield put(FetchingPageData());

    const tool = yield call(fetchTool, id);
    yield put(FetchToolSuccess(tool));

    yield put(FetchingPageDataSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
    yield put(ShowErrorScreen());
  }
}

function* DeleteTool({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Deleting Tool" }));
    yield call(deleteTool, obj);
    yield put(DeleteToolSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* UpdateTool({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Updating Tool..." }));
    const tool = yield call(updateTool, obj);
    yield put(UpdateToolSuccess(tool));
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

function* NewTool({ payload }) {
  const obj = payload;
  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Creating Tool..." }));
    yield call(newTool, obj);
    yield put(NewToolSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* PeekToolsSaga() {
  yield takeEvery(PEEK_TOOLS, PeekTools);
}

export function* FetchToolsSaga() {
  yield takeEvery(FETCH_TOOLS, FetchTools);
}

export function* FetchToolSaga() {
  yield takeEvery(FETCH_TOOL, FetchTool);
}

export function* DeleteToolSaga() {
  yield takeEvery(DELETE_TOOL, DeleteTool);
}

export function* UpdateToolSaga() {
  yield takeEvery(UPDATE_TOOL, UpdateTool);
}

export function* NewToolSaga() {
  yield takeEvery(NEW_TOOL, NewTool);
}

export default function* rootSaga() {
  yield all([
    fork(PeekToolsSaga),
    fork(FetchToolsSaga),
    fork(FetchToolSaga),
    fork(DeleteToolSaga),
    fork(UpdateToolSaga),
    fork(NewToolSaga),
  ]);
}