import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  BAN_USER,
  BAN_USER_SUCCESS,
  PAUSE_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION,
  RESUME_SUBSCRIPTION_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  fetching: false,
  deleted: false,
  banned: false,
  subscriptionPaused: false,
  subscriptionResumed: false,
  created: false,
  updated: false,
  User: { profile: {}, tools: [], subscriptions: {}, tool_details: [], restrictions: [], transactions: [] },
  Users: { total: 1, perPage: 5, page: 1, lastPage: 1, data: [] },
  banState: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS: {
      return {
        ...state,
        fetching: true
      }
    }
    case FETCH_USERS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        Users: action.payload
      }
    }
    case FETCH_USER: {
      return {
        ...state,
        fetching: true
      }
    }
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        fetching: false,
        User: action.payload
      }
    }
    case DELETE_USER: {
      return {
        ...state,
        deleted: false
      }
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        deleted: true
      }
    }
    case BAN_USER: {
      return {
        ...state,
        banned: false
      }
    }
    case BAN_USER_SUCCESS: {
      return {
        ...state,
        banned: true,
        banState: action.payload
      }
    }
    case PAUSE_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionPaused: false
      }
    }
    case PAUSE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionPaused: true
      }
    }
    case RESUME_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionResumed: false
      }
    }
    case RESUME_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionResumed: true
      }
    }
    case CREATE_USER: {
      return {
        ...state,
        created: false
      }
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        created: true
      }
    }
    case UPDATE_USER: {
      return {
        ...state,
        updated: false
      }
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        updated: true,
        User: action.payload
      }
    }
    case UPDATE_USER_PASSWORD: {
      return {
        ...state,
        updated: false
      }
    }
    case UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        updated: true
      }
    }
    default:
      return {
        ...state
      };
  }
}
