import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_EXPIRY_INTERVAL,
} from "constants/ActionTypes";
import {
  CreateExpiryIntervalSuccess,
  ShowNotificationAlert,
} from "actions";
import request from 'utils/connector';

const createExpiryInterval = async (obj) =>
  await request({
    path: '/create-expiry-interval',
    method: 'POST',
    data: obj
  }).then(res => {
    if (res.error) {
      throw new Error(JSON.stringify(res.error))
    }
    return res.data
  });

function* CreateExpiryInterval({ payload }) {
  const obj = payload;

  try {
    yield put(ShowNotificationAlert({ type: "info", message: "Creating Expiry Interval" }));
    yield call(createExpiryInterval, obj);
    yield put(CreateExpiryIntervalSuccess());
  } catch (error) {
    yield put(ShowNotificationAlert({ type: "error", message: JSON.parse(error.message).message }));
  }
}

export function* CreateExpiryIntervalSaga() {
  yield takeEvery(CREATE_EXPIRY_INTERVAL, CreateExpiryInterval);
}

export default function* rootSaga() {
  yield all([
    fork(CreateExpiryIntervalSaga),
  ]);
}