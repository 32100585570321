import {
  SHOW_ERROR_SCREEN
} from "constants/ActionTypes";

const INIT_STATE = {
  show_error_screen: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ERROR_SCREEN: {
      return {
        ...state,
        show_error_screen: true
      }
    }
    default:
      return {
        ...state,
        show_error_screen: false
      };
  }
}
