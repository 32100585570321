import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
    image: {
        borderRadius: '50%'
    }
}))

const ToolImage = ({ tool }) => {
    const classes = useStyles()

    return (
        <img
            alt={tool.name}
            src="http://stage.databycloud.com/uploads/images/asha_logo.png"
            width="40"
            height="40"
            className={classes.image}
        />
    )
}

export default ToolImage
