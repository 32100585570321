export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const FETCH_BRIEFS = 'FETCH_BRIEFS';
export const UPDATE_BRIEFS = 'UPDATE_BRIEFS';
export const SHOW_AUTH_LOADER = 'SHOW_AUTH_LOADER';
export const HIDE_AUTH_LOADER = 'HIDE_AUTH_LOADER';



export const SHOW_ERROR_SCREEN = 'SHOW_ERROR_SCREEN';



export const FETCHING_PAGE_DATA = 'FETCHING_PAGE_DATA';
export const FETCHING_PAGE_DATA_SUCCESS = 'FETCHING_PAGE_DATA_SUCCESS';



export const SHOW_NOTIFICATION_ALERT = 'SHOW_NOTIFICATION_ALERT';



export const PEEK_TOOLS = 'PEEK_TOOLS';
export const PEEK_TOOLS_SUCCESS = 'PEEK_TOOLS_SUCCESS';
export const FETCH_TOOLS = 'FETCH_TOOLS';
export const FETCH_TOOLS_SUCCESS = 'FETCH_TOOLS_SUCCESS';
export const FETCH_TOOL = 'FETCH_TOOL';
export const FETCH_TOOL_SUCCESS = 'FETCH_TOOL_SUCCESS';
export const NEW_TOOL = 'NEW_TOOL';
export const NEW_TOOL_SUCCESS = 'NEW_TOOL_SUCCESS';
export const DELETE_TOOL = 'DELETE_TOOL';
export const DELETE_TOOL_SUCCESS = 'DELETE_TOOL_SUCCESS';
export const UPDATE_TOOL = 'UPDATE_TOOL';
export const UPDATE_TOOL_SUCCESS = 'UPDATE_TOOL_SUCCESS';



export const CREATE_SUBTOOL = 'CREATE_SUBTOOL';
export const CREATE_SUBTOOL_SUCCESS = 'CREATE_SUBTOOL_SUCCESS';
export const FETCH_SUBTOOL = 'FETCH_SUBTOOL';
export const FETCH_SUBTOOL_SUCCESS = 'FETCH_SUBTOOL_SUCCESS';
export const PAUSE_SUBTOOL = 'PAUSE_SUBTOOL';
export const PAUSE_SUBTOOL_SUCCESS = 'PAUSE_SUBTOOL_SUCCESS';
export const UPDATE_SUBTOOL = 'UPDATE_SUBTOOL';
export const UPDATE_SUBTOOL_SUCCESS = 'UPDATE_SUBTOOL_SUCCESS';
export const DELETE_SUBTOOL = 'DELETE_SUBTOOL';
export const DELETE_SUBTOOL_SUCCESS = 'DELETE_SUBTOOL_SUCCESS';




export const ASSIGN_SUBTOOL = 'ASSIGN_SUBTOOL';
export const ASSIGN_SUBTOOL_SUCCESS = 'ASSIGN_SUBTOOL_SUCCESS';
export const SET_ACCESS_PERIOD = 'SET_ACCESS_PERIOD';
export const SET_ACCESS_PERIOD_SUCCESS = 'SET_ACCESS_PERIOD_SUCCESS';
export const DISABLE_TOOL_FOR_USER = 'DISABLE_TOOL_FOR_USER';
export const DISABLE_TOOL_FOR_USER_SUCCESS = 'DISABLE_TOOL_FOR_USER_SUCCESS';
export const ENABLE_TOOL_FOR_USER = 'ENABLE_TOOL_FOR_USER';
export const ENABLE_TOOL_FOR_USER_SUCCESS = 'ENABLE_TOOL_FOR_USER_SUCCESS';




export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const BAN_USER = 'BAN_USER';
export const BAN_USER_SUCCESS = 'BAN_USER_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION';
export const PAUSE_SUBSCRIPTION_SUCCESS = 'PAUSE_SUBSCRIPTION_SUCCESS';
export const RESUME_SUBSCRIPTION = 'RESUME_SUBSCRIPTION';
export const RESUME_SUBSCRIPTION_SUCCESS = 'RESUME_SUBSCRIPTION_SUCCESS';



export const FETCH_TRANSACTION_ID = 'FETCH_TRANSACTION_ID';
export const FETCH_TRANSACTION_ID_SUCCESS = 'FETCH_TRANSACTION_ID_SUCCESS';
export const VERIFY_PAYMENT = 'VERIFY_PAYMENT';
export const VERIFY_PAYMENT_SUCCESS = 'VERIFY_PAYMENT_SUCCESS';



export const CREATE_EXPIRY_INTERVAL = 'CREATE_EXPIRY_INTERVAL';
export const CREATE_EXPIRY_INTERVAL_SUCCESS = 'CREATE_EXPIRY_INTERVAL_SUCCESS';



export const ADD_DAYS_TO_USERS = 'ADD_DAYS_TO_USERS';
export const ADD_DAYS_TO_USERS_SUCCESS = 'ADD_DAYS_TO_USERS_SUCCESS';
export const REMOVE_DAYS_FROM_USERS = 'REMOVE_DAYS_FROM_USERS';
export const REMOVE_DAYS_FROM_USERS_SUCCESS = 'REMOVE_DAYS_FROM_USERS_SUCCESS';



export const FETCH_MY_PROFILE = 'FETCH_MY_PROFILE';
export const FETCH_MY_PROFILE_SUCCESS = 'FETCH_MY_PROFILE_SUCCESS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export const SHOW_MODAL_ALERT = 'SHOW_MODAL_ALERT';
export const HIDE_MODAL_ALERT = 'HIDE_MODAL_ALERT';