import {
  FETCH_TRANSACTION_ID,
  FETCH_TRANSACTION_ID_SUCCESS,
  VERIFY_PAYMENT,
  VERIFY_PAYMENT_SUCCESS,
} from 'constants/ActionTypes';

export const FetchTransactionId = (transaction_id) => {
  return {
    type: FETCH_TRANSACTION_ID,
    payload: transaction_id
  };
};

export const FetchTransactionIdSuccess = (transaction) => {
  return {
    type: FETCH_TRANSACTION_ID_SUCCESS,
    payload: transaction
  };
};

export const VerifyPayment = (obj) => {
  return {
    type: VERIFY_PAYMENT,
    payload: obj
  };
};

export const VerifyPaymentSuccess = () => {
  return {
    type: VERIFY_PAYMENT_SUCCESS
  };
};