import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { HideModalAlert } from 'actions';

export default function ModalAlert() {
    const ModalAlertState = useSelector(({ ModalAlert }) => ModalAlert);

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [buttons, setButtons] = React.useState([]);

    useEffect(() => {
        setOpen(ModalAlertState.open);
        setTitle(ModalAlertState.title);
        setMessage(ModalAlertState.message);
        setButtons(ModalAlertState.buttons);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ModalAlertState.open])

    const handleClose = () => {
        dispatch(HideModalAlert())
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogTitle style={{ cursor: 'move' }} id="dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        buttons.map((button, i) => {
                            return (
                                <Button
                                    key={i}
                                    autoFocus={button.autoFocus || false}
                                    onClick={button.onClick || handleClose}
                                    color={button.color || "primary"}>
                                    {button.text}
                                </Button>
                            )
                        })
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}