import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  BAN_USER,
  BAN_USER_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  PAUSE_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION,
  RESUME_SUBSCRIPTION_SUCCESS
} from 'constants/ActionTypes';

export const CreateUser = (obj) => {
  return {
    type: CREATE_USER,
    payload: obj
  };
};

export const CreateUserSuccess = () => {
  return {
    type: CREATE_USER_SUCCESS
  };
};

export const FetchUser = (id) => {
  return {
    type: FETCH_USER,
    payload: id
  };
};

export const FetchUserSuccess = (user) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user
  };
};

export const FetchUsers = (page, searchString, rowsPerPage) => {
  return {
    type: FETCH_USERS,
    payload: { page, searchString, rowsPerPage }
  };
};

export const FetchUsersSuccess = (users) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users
  };
};

export const DeleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: id
  };
};

export const DeleteUserSuccess = () => {
  return {
    type: DELETE_USER_SUCCESS
  };
};

export const BanUser = (id, banned) => {
  return {
    type: BAN_USER,
    payload: { id, banned }
  };
};

export const BanUserSuccess = (banned) => {
  return {
    type: BAN_USER_SUCCESS,
    payload: banned
  };
};

export const UpdateUser = (id, user) => {
  return {
    type: UPDATE_USER,
    payload: { id, user }
  };
};

export const UpdateUserSuccess = (user) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user
  };
};

export const UpdateUserPassword = (id, password) => {
  return {
    type: UPDATE_USER_PASSWORD,
    payload: { id, password }
  };
};

export const UpdateUserPasswordSuccess = () => {
  return {
    type: UPDATE_USER_PASSWORD_SUCCESS
  };
};

export const PauseSubscription = (user_id) => {
  return {
    type: PAUSE_SUBSCRIPTION,
    payload: { user_id }
  };
};

export const PauseSubscriptionSuccess = () => {
  return {
    type: PAUSE_SUBSCRIPTION_SUCCESS
  };
};

export const ResumeSubscription = (user_id) => {
  return {
    type: RESUME_SUBSCRIPTION,
    payload: { user_id }
  };
};

export const ResumeSubscriptionSuccess = () => {
  return {
    type: RESUME_SUBSCRIPTION_SUCCESS
  };
};