import React, { Suspense, useEffect, useState } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { NavBar, TopBar } from './components'

import useRouter from 'utils/useRouter';
import ErrorScreen from 'views/ErrorScreen'
import { FetchBriefs } from 'actions'
import { useLocation } from 'react-router'

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    topBar: {
        zIndex: 2,
        position: 'relative',
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto',
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 auto',
    },
}))

const DashboardLayout = ({ classes, handleNavBarMobileClose, openNavBarMobile, route }) => {
    return (
        <div className={classes.container}>
            <NavBar
                className={classes.navBar}
                onMobileClose={handleNavBarMobileClose}
                openMobile={openNavBarMobile}
            />
            <main className={classes.content}>
                <Suspense fallback={<LinearProgress />}>
                    {renderRoutes(route.routes)}
                </Suspense>
            </main>
        </div>
    )
}

const Dashboard = (props) => {
    const { route } = props;

    const classes = useStyles();

    const router = useRouter();

    const dispatch = useDispatch();

    const [openNavBarMobile, setOpenNavBarMobile] = useState(false)

    const [showErrorScreen, setShowErrorScreen] = useState(false)

    const AuthState = useSelector(({ Auth }) => Auth);

    const ErrorScreenState = useSelector(({ ErrorScreen }) => ErrorScreen);

    const location = useLocation();

    useEffect(() => {
        window.dispatchEvent(new Event('sa:unmounted'))
    }, [location.pathname])

    useEffect(() => {
        if (!AuthState.authUser) {
            router.history.push('/auth/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AuthState.authUser])

    useEffect(() => {
        dispatch(FetchBriefs())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setShowErrorScreen(ErrorScreenState.show_error_screen);
    }, [ErrorScreenState.show_error_screen])

    const handleNavBarMobileOpen = () => {
        setOpenNavBarMobile(true);
    }

    const handleNavBarMobileClose = () => {
        setOpenNavBarMobile(false);
    }

    return (
        <div className={classes.root}>
            <TopBar
                className={classes.topBar}
                onOpenNavBarMobile={handleNavBarMobileOpen}
            />
            {
                showErrorScreen
                    ?
                    <ErrorScreen />
                    :
                    !AuthState.brief_loaded
                        ?
                        <LinearProgress />
                        :
                        <DashboardLayout
                            classes={classes}
                            openNavBarMobile={openNavBarMobile}
                            handleNavBarMobileClose={handleNavBarMobileClose}
                            route={route}
                        />
            }
        </div>
    )
}

Dashboard.propTypes = {
    route: PropTypes.object,
}

export default Dashboard
